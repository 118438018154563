import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';

// Components
import {
  Facebook,
  Footer,
  MemberAvatar,
  PostCard,
  SiteNav,
  Twitter,
  Website,
  Wrapper,
} from 'components';
// @ts-ignore
import { Phone, TabletUp } from 'components/MediaQuery';

// Styles
import { HeroHeader } from 'styles';

// Layouts
import { SEOContent } from 'layouts';

import {
  AuthorBio,
  AuthorMeta,
  Bull,
  HiddenMobile,
  inner,
  outer,
  PostFeed,
  PostFeedRaise,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
  SocialLink,
} from 'styles';

// Hooks
// TODO implement an external hook.
import { useMemberHero } from 'hooks';

// Misc.
import { BurgerMenu } from 'components/SiteNav/BurgerMenu';

// Types
import { Post } from 'components/PostCard/types';
interface PostType {
  data: Post;
}

const MemberPage = (props: any) => {
  // From GQL
  const author = get(props, 'data.allAirtable.nodes[0].author', {}) || {};
  const bio = get(author, 'bio', '') || '';
  const facebook = get(author, 'facebook', '');
  const fullName = get(author, 'full_name', '') || '';
  const organization = get(author, 'organization', '');
  const profileImage =
    get(author, 'profile_picture[0].thumbnails.large.url', '') || '';
  const posts = get(author, 'Posts', []) || [];
  const twitter = get(author, 'twitter', '');
  const website = get(author, 'website', '');
  const totalCount = posts.length;

  // From Hook
  const { src } = useMemberHero();

  // TODO clean up this Helmet. Extract?
  return (
    <SEOContent title={fullName}>
      <Wrapper>
        <HeroHeader imgSrc={src} css={[outer, SiteHeader]}>
          <div css={inner}>
            <Phone>
              <BurgerMenu />
            </Phone>
            <TabletUp>
              <SiteNav isHome={false} />
            </TabletUp>
            <SiteHeaderContent>
              <MemberAvatar name={fullName} profileImage={profileImage} />
              <SiteTitle>{fullName}</SiteTitle>
              {bio && <AuthorBio>{bio}</AuthorBio>}
              <AuthorMeta>
                {organization && (
                  <div css={HiddenMobile}>
                    {organization} <Bull>&bull;</Bull>
                  </div>
                )}
                <div css={HiddenMobile}>
                  {totalCount > 1 && `${totalCount} posts`}
                  {totalCount === 1 && '1 post'}
                  {totalCount === 0 && 'No posts'}
                </div>
                {website && (
                  <div>
                    <a
                      className="social-link-wb"
                      css={SocialLink}
                      href={website}
                      title="Website"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Website />
                    </a>
                  </div>
                )}
                {twitter && (
                  <a
                    className="social-link-tw"
                    css={SocialLink}
                    href={`https://twitter.com/${twitter}`}
                    title="Twitter"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Twitter />
                  </a>
                )}
                {facebook && (
                  <a
                    className="social-link-fb"
                    css={SocialLink}
                    href={`https://www.facebook.com/${facebook}`}
                    title="Facebook"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Facebook />
                  </a>
                )}
                {/* TODO: RSS for author */}
              </AuthorMeta>
            </SiteHeaderContent>
          </div>
        </HeroHeader>
        <main css={[SiteMain, outer]}>
          <div css={inner}>
            <div css={[PostFeed, PostFeedRaise]}>
              {posts.map(({ data }: PostType) => (
                <PostCard
                  key={data.post_id}
                  post={data}
                  postId={data.post_id}
                />
              ))}
            </div>
          </div>
        </main>
        <Footer />
      </Wrapper>
    </SEOContent>
  );
};

export default MemberPage;

export const query = graphql`
  query ($memberSlug: String) {
    allAirtable(
      filter: {
        table: { eq: "Members" }
        data: { member_slug: { eq: $memberSlug } }
      }
    ) {
      nodes {
        author: data {
          last_name
          full_name
          first_name
          organization
          member_slug
          twitter
          bio
          website
          profile_picture {
            thumbnails {
              large {
                url
              }
            }
          }
          Posts {
            data {
              post_id
              time_to_read
              markdown_content
              created_date
              title
              tags
              excerpt
              attachments {
                localFiles {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              author {
                data {
                  first_name
                  full_name
                  last_name
                  member_slug
                }
              }
            }
          }
        }
      }
    }
  }
`;
